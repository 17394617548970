/*
Replace
menuDish with your resource Name :) Take care about different writings!
/!\ MenuDish menuDish MenuDishes menuDishes /!\
Keep an eye on pluralized namings in here :)

*/
/**
 *  Sagas for the MenuDishes resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery, select } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import DataLayerHelper from "../../utils/dataLayer";

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getMenuDish operation
 */
export function* list(action) {
  const { locationId, date_start, date_end } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.MenuDishes.listMenuDishes,
      { location_id: locationId, date_start: date_start, date_end: date_end },
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
    DataLayerHelper.addErrorEvent(
      "Load MenuDish List",
      e.error || e.message || "MenuDish Error"
    );
  }
}

/** Show Saga
 *  @description: connects to the showMenuDish operation
 *  @param {number} action.payload the MenuDish id
 */
export function* show(action) {
  const { menuDishId, locationId } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.MenuDishes.showMenuDish,
      { id: menuDishId, location_id: locationId },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj.data });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* create(action) {
  const { locationId, menuDish } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.MenuDishes.createMenuDish,
      { location_id: locationId, menu_dish: menuDish },
      { requestInterceptor: applyHeaders(headers) }
    );

    yield put({ type: actions.createSuccess, payload: payload.obj.data });

    if (payload.obj.included) {
      yield* storeIncluded({ payload: payload.obj.included });
    }

    if (action.payload.callback) {
      action.payload.callback();
    }

    DataLayerHelper.addSuccessEvent(
      "Create MenuDish",
      `data to log, maybe id?`
    );
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

export function* update(action) {
  const { menuDishId, locationId, menuDish } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.MenuDishes.updateMenuDish,
      { id: menuDishId, location_id: locationId, menu_dish: menuDish },
      { requestInterceptor: applyHeaders(headers) }
    );

    yield put({ type: actions.updateSuccess, payload: payload.obj.data });

    yield* storeIncluded({ payload: payload.obj.included });

    if (action.payload.callback) {
      action.payload.callback();
    }

    DataLayerHelper.addSuccessEvent("Updated MenuDish");
  } catch (e) {
    yield put({ type: actions.updateFail, payload: e });
  }
}

export function* remove(action) {
  const { menuDishId, locationId } = action.payload;
  const currentUser = yield select(
    (state) => state.professionalAccount.currentUser
  );
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    yield call(
      SwaggerClient.apis.MenuDishes.deleteMenuDish,
      { id: menuDishId, location_id: locationId },
      { requestInterceptor: applyHeaders(headers) }
    );

    yield put({ type: actions.removeSuccess });

    if (action.payload.callback) {
      action.payload.callback();
    }

    DataLayerHelper.addSuccessEvent("Removed MenuDish");
  } catch (e) {
    yield put({ type: actions.removeFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* transactionsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
  yield takeEvery(actions.remove, remove);
}
export default transactionsSaga;
