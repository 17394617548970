/**
 * The root reducer function. This function is used by the store to mutate its state. All reducers should subscirbe in this file in order to have effect
 */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { i18nState } from "redux-i18n";
import servWork from "./servWork/reducers";
import notifications from "./notifications/reducers";
import account from "./account/reducers";
import professionalAccount from "./professional_account/reducers";
import ingredients from "./ingredients";
import recipeIngredients from "./recipe_ingredients";
import nestedRecipeIngredients from "./nested_recipe_ingredients";
import dishRecipeIngredients from "./dish_recipe_ingredients";
import recipeStepIngredients from "./recipe_step_ingredients";
import recipes from "./recipes";
import recipeSteps from "./recipe_steps";
import ingredientsGroups from "./ingredients_groups";
import allergens from "./allergens";
import counters from "./counters";
import articles from "./articles";
import suppliers from "./suppliers";
import clients from "./clients";
import nutrients from "./nutrients";
import locations from "./locations";
import techniques from "./techniques";
import techniqueSteps from "./technique_steps";
import timeslots from "./timeslots";
import ingredientNutrients from "./ingredient_nutrients";
import certificates from "./certificates";
import products from "./products";
import dishes from "./dishes";
import menuDishes from "./menu_dishes";
import users from "./users";
import courses from "./courses";
import lessons from "./lessons";
import lessonChapters from "./lesson_chapters";
import lessonChapterAnswers from "./lesson_chapter_answers";
import professionalUsers from "./professional_users";
import dishBundles from "./dish_bundles";
import dishRecipeStepIngredients from "./dish_recipe_step_ingredients";
import feedbacks from "./feedbacks";
// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports

/** exports all reducers through the combineReducers function */
const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    i18nState,
    courses,
    lessons,
    lessonChapters,
    lessonChapterAnswers,
    servWork,
    notifications,
    account,
    ingredients,
    recipeIngredients,
    dishRecipeIngredients,
    recipeStepIngredients,
    recipes,
    recipeSteps,
    ingredientsGroups,
    nestedRecipeIngredients,
    allergens,
    counters,
    articles,
    nutrients,
    locations,
    techniques,
    timeslots,
    techniqueSteps,
    suppliers,
    clients,
    ingredientNutrients,
    certificates,
    products,
    dishes,
    menuDishes,
    users,
    professionalUsers,
    professionalAccount,
    dishBundles,
    dishRecipeStepIngredients,
    feedbacks,
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded reducers
  });

export default reducers;
