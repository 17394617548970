/**
 * The Root container
 */
import React from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import MainNavbar from "../components/MainNavbar";
import OfflineBar from "../components/OfflineBar";
import Loadable from "react-loadable";
import FourOFour from "./FourOFour";
import PrivateRoute from "../components/PrivateRoute";
import UserInvite from "./UserInvite";
import UserEdit from "./UserEdit";
import Footer from "../components/Footer/Footer";
import LocationRoutes from "./LocationRoutes";
import moment from "moment";
import momentTranslations from "../utils/momentTranslations";

export const routes = {
  dashboard: "/",
  users: {
    list: "/locations/:location_id/users",
    show: "/locations/:location_id/users/:id",
    edit: "/locations/:location_id/users/:id/edit",
    invite: "/locations/:location_id/users/invite",
  },
};

/**
 * Loadable import for Users container
 */
const Users = Loadable({
  loader: () => import(/* webpackChunkName: "Users" */ "./Users"),
  loading: () => null,
  modules: ["Users"],
});
/**
/**
 * Loadable import for Courses container
 */
const Courses = Loadable({
  loader: () => import(/* webpackChunkName: "Courses" */ "./Courses"),
  loading: () => null,
  modules: ["Courses"],
});
/**
 * Loadable import for Lesson container
 */
const Lesson = Loadable({
  loader: () => import(/* webpackChunkName: "Lesson" */ "./Lesson"),
  loading: () => null,
  modules: ["Lesson"],
});
/**
 * Loadable import for UserDetails container
 */
const UserDetails = Loadable({
  loader: () => import(/* webpackChunkName: "UserDetails" */ "./UserDetails"),
  loading: () => null,
  modules: ["UserDetails"],
});
/**
/**
 * Loadable import for AllSteps container
 */
const AllSteps = Loadable({
  loader: () => import(/* webpackChunkName: "UserDetails" */ "./AllSteps"),
  loading: () => null,
  modules: ["AllSteps"],
});
/**
 * Loadable import for Dashboard container
 */
const Dashboard = Loadable({
  loader: () => import(/* webpackChunkName: "Dashboard" */ "./Dashboard"),
  loading: () => null,
  modules: ["Dashboard"],
});
/**
 * Loadable import for Locations container
 */
const Locations = Loadable({
  loader: () => import(/* webpackChunkName: "Locations" */ "./Locations"),
  loading: () => null,
  modules: ["Locations"],
});
/**
 * Loadable import for MenuPlanning container
 */
const MenuPlanner = Loadable({
  loader: () => import(/* webpackChunkName: "MenuPlanning" */ "./MenuPlanner"),
  loading: () => null,
  modules: ["MenuPlanner"],
});
/**
 * Loadable import for MenuPlanning container
 */
const MenuDishPlanner = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MenuDishPlanner" */ "./MenuDishPlanner"),
  loading: () => null,
  modules: ["MenuDishPlanner"],
});
/**
 * Loadable import for Account container
 */
const Account = Loadable({
  loader: () => import(/* webpackChunkName: "Account" */ "./Account"),
  loading: () => null,
  modules: ["Account"],
});

const UserProfile = Loadable({
  loader: () => import(/* webpackChunkName: "UserProfile" */ "./UserProfile"),
  loading: () => null,
  modules: ["UserProfile"],
});

const RecipeDetails = Loadable({
  loader: () =>
    import(/* webpackChunkName: "RecipeDetails" */ "./RecipeDetails"),
  loading: () => null,
  modules: ["RecipeDetails"],
});

const MenuDish = Loadable({
  loader: () => import(/* webpackChunkName: "MenuDish" */ "./MenuDish"),
  loading: () => null,
  modules: ["MenuDish"],
});

const MenuDishRecipe = Loadable({
  loader: () =>
    import(/* webpackChunkName: "MenuDishRecipe" */ "./MenuDishRecipe"),
  loading: () => null,
  modules: ["MenuDishRecipe"],
});

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded loadable imports

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
const Root = () => {
  const locale = useSelector((state) => state.i18nState?.lang) || "en";
  moment.locale(locale, momentTranslations(locale));

  return (
    <div className="root">
      <OfflineBar />
      <MainNavbar />
      <div className="content">
        <Switch>
          <PrivateRoute exact path={routes.dashboard} component={Dashboard} />
          <PrivateRoute
            exact
            path={routes.users.invite}
            component={UserInvite}
          />
          <Route path={"/account/login"} component={Account} />
          <Route path={"/account/register"} component={Account} />
          <Route path={"/account/recover"} component={Account} />
          <Route path={"/account/reset"} component={Account} />
          <PrivateRoute path={"/profile"} component={UserProfile} />
          <PrivateRoute exact path={"/locations"} component={Locations} />
          <PrivateRoute path={"/dashboard"} component={Dashboard} />
          {/* ACADEMY PATHS */}
          <PrivateRoute exact path={"/academy/courses"} component={Courses} />
          <PrivateRoute
            exact
            path={"/academy/courses/course-:course/lesson-:lesson"}
            component={Lesson}
          />

          {/* MENU PLANNER PATHS */}
          <LocationRoutes>
            <PrivateRoute exact path={routes.users.list} component={Users} />
            <PrivateRoute
              exact
              path={routes.users.show}
              component={UserDetails}
            />
            <PrivateRoute exact path={routes.users.edit} component={UserEdit} />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:master_recipe_id/basisrecipe/:recipe"
              }
              component={MenuDishRecipe}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:master_recipe_id/basisrecipe/:recipe/recipe-steps/:recipe_step_id?"
              }
              component={MenuDishRecipe}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:master_recipe_id/basisrecipe/:nested_recipe_id/:recipe/recipe-steps/:recipe_step_id?"
              }
              component={MenuDishRecipe}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:master_recipe_id/basisrecipe/:nested_recipe_id/:recipe"
              }
              component={MenuDishRecipe}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:recipe/overview"
              }
              component={RecipeDetails}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:recipe"
              }
              component={MenuDishRecipe}
            />
            <PrivateRoute
              exact
              path={"/locations/:location_id/menu-planner/:menu_dish_id"}
              component={MenuDish}
            />
            <PrivateRoute
              exact
              path={"/locations/:location_id/menu-planner/"}
              component={MenuPlanner}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:dish/recipe-steps/:recipe_step_id?"
              }
              component={MenuDishRecipe}
            />
            <PrivateRoute
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:recipe/recipe-steps/:recipe_step_id?"
              }
              component={MenuDishRecipe}
            />

            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/all-steps"
              }
              component={AllSteps}
            />
            <PrivateRoute
              path={
                "/locations/:location_id/menu-planner/:menu_dish_id/recipes/:recipe/all-steps"
              }
              component={AllSteps}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/:day/daily/:counter/menu-dish-planner"
              }
              component={MenuDishPlanner}
            />
            <PrivateRoute
              exact
              path={
                "/locations/:location_id/menu-planner/:timeslot/:day/daily/:counter/menu-dish-planner/:menu_dish_id/edit"
              }
              component={MenuDishPlanner}
            />
          </LocationRoutes>

          {/* // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT */}
          {/* new routes */}
          <Route component={FourOFour} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
