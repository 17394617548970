/*
Replace
location with your resource Name :) Take care about different writings!
/!\ Location location Locations locations /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const locationSlice = createSlice({
  name: "locations",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listLocations,
  show: showLocation,
  create: createLocation,
  update: updateLocation,
} = locationSlice.actions;

export const actions = locationSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const locations = (state) =>
  state.locations.idsList.map((id) => state.locations.dictionary[id]);
export const location = (state) =>
  state.locations.showId && state.locations.dictionary[state.locations.showId];
export const locationAttributes = (state) => location(state)?.attributes;
export const locationRelationships = (state) => location(state)?.relationships;
export const locationId = (state) => state.locations.showId;

export const getLocation = createSelector(
  (state) => state.locations.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getLocationAttributes = createSelector(
  (state) => state.locations.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getLocationRelationships = createSelector(
  (state) => state.locations.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getLocations = createSelector(
  (state) => state.locations.dictionary,
  (dictionary) => {
    return dictionary && Object.values(dictionary);
  }
);

export default locationSlice.reducer;
