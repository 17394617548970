import React, { useEffect, useState } from "react";
import { useParams, useLocation as useRouterLocation } from "react-router-dom";
import { getLocation, showLocation } from "../redux/locations";
import { useDispatch, useSelector } from "react-redux";
import { getClient, showClient } from "../redux/clients";
import fetchAndParseCSV from "./fetchAndParseCSV";
import buildHeaders from "./buildHeaders";
import { API_URL } from "./apiRequest";
import moment from "moment";

export const useLocationId = () => {
  const { location_id } = useParams();

  return location_id;
};

export const useLocation = () => {
  const dispatch = useDispatch();
  const locationId = useLocationId();

  const location = useSelector((state) => getLocation(state));

  useEffect(() => {
    if (location && location.id === locationId) {
      return;
    }

    if (!locationId) {
      return;
    }

    dispatch(showLocation(locationId));
  }, [dispatch, locationId, location]);

  return location;
};

export const useClient = (clientId) => {
  const dispatch = useDispatch();

  const client = useSelector((state) => getClient(state, clientId));

  useEffect(() => {
    if (client) {
      return;
    }

    dispatch(showClient({ clientId }));
  }, [dispatch, clientId, client]);

  return client;
};

export const useQueryParams = () => {
  const { search } = useRouterLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useCurrentUser = () =>
  useSelector((state) => state.professionalAccount.currentUser);

export const useCSVData = (url) => {
  const currentUser = useCurrentUser();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [headers, setHeaders] = useState(buildHeaders(currentUser));

  useEffect(() => {
    setHeaders(buildHeaders(currentUser));
  }, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      setError(null);
      setLoading(true);
      try {
        const jsonData = await fetchAndParseCSV(url, { mode: "cors", headers });
        setData(jsonData);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url, headers]);

  return { data, loading, error };
};

export const useShoppingListData = (locationId, from, to) => {
  const currentUser = useCurrentUser();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [headers] = useState(buildHeaders(currentUser));

  const formatDate = (d) => moment(d).format(moment.HTML5_FMT.DATE);
  const url = `${API_URL}/api/v1/locations/${locationId}/shopping_list.json?start_date=${formatDate(
    from
  )}&end_date=${formatDate(to)}`;

  useEffect(() => {
    if (!url) {
      return;
    }

    const fetchData = async () => {
      setError(null);
      setLoading(true);
      try {
        const response = await fetch(url, {
          method: "get",
          headers,
        });
        const body = await response.json();
        setData(body);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, [url, headers]);

  return { data, loading, error };
};
